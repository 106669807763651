<template>
  <v-layout class="rounded rounded-md">
    <app-header />

    <v-main>
      <router-view />
    </v-main>
  </v-layout>
</template>

<script setup>
import { onMounted } from "vue";

import AppHeader from "@/components/layout/AppHeader.vue";
import { useUserStore } from "@/stores/userStore";

onMounted(() => {
  useUserStore().checkUserToken();
});
</script>
