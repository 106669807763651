<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props">
        <v-icon>mdi-translate</v-icon>
        {{ $i18n.locale }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(lang, index) in languages"
        :key="index"
        @click="changeLanguage(lang)"
      >
        <v-list-item-title>{{ lang.label }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      currentLanguage: "English",
      languages: [
        { code: "en", label: "English" },
        { code: "pl", label: "Polski" },
      ],
    };
  },
  methods: {
    changeLanguage({ code }) {
      localStorage.setItem('language', code);
      this.$i18n.locale = code;
    },
  },
};
</script>